import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import qs from 'query-string';

// assets import
import './_edit-profile.scss';
import { verifyEmail } from '@src/http/UserRepository';
import { useInput, detectMob } from '@src/Helper';

function EmailVerification({ location }) {
  const emailVerifiedLogo = useStaticQuery(graphql`
    query queryVerifyEmailLogo {
      logo: file(relativePath: {eq: "icons/email_verified_ic.png"}) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // STATES
  const verification = useInput(null);
  const id = useInput('verification');
  const message = useInput({
    title: 'Verifying...',
    body: '',
  })

  // API CALLS
  const verifyEmailChanges = (token) => {
    verifyEmail(token).then(res => {
      verification.setValue(res.data.message);
      message.setValue({
        title: "Your e-mail has been Verified!",
        body: ''
      })
    }).catch(err => {
      verification.setValue(null);
      message.setValue({
        title: "Oops...looks like there's something wrong!",
        body: "Fail to change your email. Try to re-click the button in the email we sent you or contact our support."
      })
      console.log(err.response)
    })
  }

  // EFFECTS
  useEffect(() => {
    const queryString = qs.parse(location.search);
    verifyEmailChanges(queryString);

    if (detectMob()) {
      id.setValue('m-verification');
    }
  }, [])



  return (
    <div id={id.value} className="section-128">
      <div className="container text-center">
        <Image className="verification-company-logo w-50 mx-auto" fluid={emailVerifiedLogo.logo.childImageSharp.fluid} alt="executivemafia" />
        <h1 className="title text-broccoli-green mt-4">{message.value.title}</h1>
        <p className="subcaption mt-2">{message.value.body} </p>
        <Link
          to="/dashboard"
          className="button button-mafia-primary mt-4 d-inline-block"
        >
          Go to Dashboard
        </Link>
      </div>
    </div>
  )
}

export default EmailVerification;